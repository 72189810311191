import React, { ComponentType, ReactNode } from 'react'
import { Field as FFField, FieldInputProps } from 'react-final-form'
import { UiComponent } from 'components/ui/types'

type FieldProps = {
  name: string
  label?: ReactNode
  labelPosition?: 'top' | 'right'
  noLabelMargin?: boolean
  input: ComponentType<FieldInputProps<any>>
  required?: boolean
  // For setting value to boolean
  type?: 'text' | 'checkbox' | 'radio'
} & UiComponent

const Field: ComponentType<FieldProps> = ({
  name,
  label,
  labelPosition = 'top',
  noLabelMargin = false,
  input: Input,
  required = false,
  type = 'text',
  className = ''
}) => {
  const topLabel = labelPosition === 'top'
  return (
    <FFField
      name={name}
      type={type}
      render={({ input, meta }) => {
        const error = meta.touched && (meta.error || meta.submitError)
        return (
          <div className={`view-vertical pt-2 pb-2 ${className}`}>
            <label
              htmlFor={name}
              className={`flex ${
                topLabel
                  ? 'view-vertical'
                  : 'flex-row-reverse items-start justify-end'
              }`}
            >
              <span
                className={`label ${topLabel ? '' : 'ml-2'} ${
                  noLabelMargin ? '' : 'mb-1'
                }`}
              >
                {label} {required && '*'}
              </span>
              <Input {...input} required={required} />
            </label>
            {error && <span>{error}</span>}
          </div>
        )
      }}
    />
  )
}

export default Field
