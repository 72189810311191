import { ValidationError } from 'data/authentication'
import { FORM_ERROR, SubmissionErrors } from 'final-form'

export type FormErrors = Record<string, string>
export type FormValidationRule =
  | 'required'
  | 'email'
  | 'password'
  | 'passwordRepeat'
export type FormValidator<T> = {
  [K in keyof T]?: FormValidationRule[]
}

const isEmail = (value: string): boolean =>
  !value ||
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    value
  )

// min 8 and max 64 characters
const isPassword = (value: string): boolean => !value || /^.{8,64}$/.test(value)

const isPasswordRepeat = (
  repeatedPassword: string,
  password: string | undefined
) =>
  (!repeatedPassword && !password) ||
  (password && password === repeatedPassword)

export function formValidation<T> (
  data: T,
  validator: FormValidator<T>
): FormErrors {
  const errors: FormErrors = {}
  // @ts-ignore
  Object.entries<FormValidationRule[]>(validator).forEach(([key, rules]) => {
    rules.reverse().forEach((r) => {
      // @ts-ignore
      if (r === 'required' && (!data[key] || data[key].length === 0)) {
        errors[key] = 'Muss befüllt sein.'
      }
      // @ts-ignore
      if (r === 'email' && !isEmail(data[key])) {
        errors[key] = 'Muss eine gültige Email-Adresse sein.'
      }
      // @ts-ignore
      if (r === 'password' && !isPassword(data[key])) {
        errors[key] = 'Muss 8-64 Zeichen lang sein.'
      }
      // @ts-ignore
      if (
        r === 'passwordRepeat' &&
        // @ts-ignore
        !isPasswordRepeat(data[key], data.password)
      ) {
        errors[key] = 'Passwort stimmt nicht überein'
      }
    })
  })
  return errors
}

export const formSubmitValidation = (
  errors: ValidationError[]
): SubmissionErrors | null => {
  if (!errors) return null
  const output: { [key: string]: string } = {}
  errors.forEach((e) => {
    // Parsing because server returns fields like: register.arg1.username
    // removing leading fieldname from error message
    const errorField = e.field.substring(e.field.lastIndexOf('.') + 1)
    if (errorField === 'base') { output[FORM_ERROR] = e.message.replace(/^base /, '') } else {
      output[errorField] = e.message.replace(
        new RegExp('^' + errorField + ' '),
        ''
      )
    }
  })
  return output
}
