import React, { ComponentType } from 'react'
import { useFormState } from 'react-final-form'
import Button from 'components/ui/basic/Button'
import { Spinner } from 'components/ui/basic/Spinner'
import { UiComponent } from 'components/ui/types'

type FormSubmissionPanelProps = {
  buttonText: string
} & UiComponent

export const FormSubmissionPanel: ComponentType<FormSubmissionPanelProps> = ({
  buttonText,
  className = ''
}) => {
  const { submitting } = useFormState()
  return (
    <div className={`view-horizontal space-x-2 items-center ${className}`}>
      <Button
        type="submit"
        className={'btn btn-primary space-x-2'}
        disabled={submitting}
      >
        <span>{buttonText}</span>
      </Button>
      <Spinner loading={submitting} />
    </div>
  )
}
